import React from 'react'
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'

// Assets
import logo from '../images/staffy-logo-orange.svg'
import newlogo from '../images/new-staffy-logo-orange.svg'

import { HeaderLGStyle, CopyStyle, LinkButton } from './UI'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const CallToAction = ({ data, header, copy, ctas, image }) => {
  const imageData = useStaticQuery(graphql`
    {
      cottonballs: file(relativePath: { eq: "cottonballs.png" }) {
        childImageSharp {
          gatsbyImageData(width: 512, quality: 90)
        }
      }
    }
  `)

  return (
    <section tw="bg-[#E9F1F3] px-7 pb-7 pt-0 lg:(px-0 py-24) font-mont">
      <div tw="flex flex-col lg:(grid grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative)">
        <div
          css={[
            tw`bg-white p-9 order-last lg:(col-start-2 col-span-10 py-14 pl-20 pr-64 -left-20 relative)`,
          ]}
        >
          <h2 css={[HeaderLGStyle, tw`mb-6`]}>{header}</h2>
          <p css={[CopyStyle, tw`mb-6 lg:(mb-10 pr-8)`]}>{copy}</p>
          {ctas &&
            ctas.map((cta, index) => (
              <div
                key={`cta-button-${index}`}
                css={[
                  tw`inline-block w-full lg:(w-auto)`,
                  index !== ctas.length - 1 && tw`mb-3.5 lg:mb-0 mr-5`,
                ]}
              >
                <LinkButton destination={cta.destination}>
                  {cta.copy}
                </LinkButton>
              </div>
            ))}
        </div>

        <div tw="order-first relative lg:(absolute w-full h-full col-start-11 col-span-5) font-mont">
          {/* Image */}
          <GatsbyImage
            tw="object-cover w-full h-full lg:absolute"
            image={
              image
                ? image.gatsbyImageData
                : imageData.cottonballs.childImageSharp.gatsbyImageData
            }
            alt={image ? image.filename : 'cotton balls'}
          />
          {/* Logo */}
          <div tw="absolute top-0 w-full h-full flex items-center justify-center font-mont">
            <img tw="max-w-full max-h-full w-[70%]" src={newlogo} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction
